@import "../styles/theme.less";

.credit-btn-wrapper {
  background-color: #colors[primary];
  width: 100%;
  max-width: 300px;
  height: 55px;
  border-radius: 10px;
  padding: 4px 10px 4px 10px;
  margin-left: 5px;
  .top-wrapper {
    // background-color: red;
    height: 40%;
    border-bottom: solid 1px #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .middle-wrapper {
    // background-color: yellow;
    height: 30%;
    border-bottom: solid 1px #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .bottom-wrapper {
    // background-color: green;
    height: 30%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.credit-btn-label {
  color: #colors[lightPrimary];
  font-size: 10px;
  margin-right: 2px;
}

.credit-btn-value {
  color: #colors[lightPrimary];
  font-weight: bold;
  font-size: 12px;
}


.invoice-item-wrapper {
  width: 100%;
  margin-bottom: 30px;
}

.invoice-item-top-label {
  color: #000;
}

.invoice-item-top-amount {
  color: #000;
}

.invoice-item-row {
  padding-top: 4px;
  padding-bottom: 4px;
}
@primary-color: #1DA57A;