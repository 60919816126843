@import "../styles/theme.less";
@import "../styles/buttons.less";
@import "../styles/cards.less";
@import "../styles/tables.less";
@import "../styles/forms.less";

html {
	height: 100%;
	width: 100%;
}

body {
	font-family: Mulish;
	width: 100%;
	height: 100%;
}

input[type="text"],
input[type="number"],
textarea.ant-input {
	border-radius: 8px;
	width: 100%;
	background: #colors[grey];
	border: none;
	height: 45px;
}

.center-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 70vh;
}

.load-more {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 70px;
}

.redirect-link {
	cursor: pointer;
	font-size: 16px;
	color: #colors[primary];
}

@media (max-width: 480px) {
	.ant-steps-horizontal.ant-steps-label-horizontal {
		flex-direction: row !important;
	}

	.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
		.ant-steps-item {
		padding-left: 18.5%;
	}
	.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
		margin: 0;
		position: absolute;
		top: 16px;
		right: 44.5%;
		width: 63px;
		border-top: 1px dashed;
	}

	.ant-steps-item.ant-steps-item-process.ant-steps-item-active:first-child
		.ant-steps-item-content {
		display: none;
	}

	.ant-steps-item:first-child > .ant-steps-item-container > .ant-steps-item-content {
		display: none !important;
	}
}

.notification-bar {
	background-color: #colors[lightblue];
}

.add-free-product-bar {
	background-color: #colors[lightblue];
}


.add-new-phone-number-wrapper {
	background-color: #colors[lightblue];
	padding: 10px;
	width: 100%;
	height: 100%;
}

.yellow-btn {
	background-color: #colors[yellow];
}

.primary-btn {
	background-color: #colors[primary];
}

.grey-btn {
  background-color: #colors[grey];
}
.ant-message {
	z-index: 88888;
}
@primary-color: #1DA57A;