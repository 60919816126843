@import "./theme.less";

.he-button {
  background: #colors[primary];
  box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
  border-radius: 3px;
  padding: 2em;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;

  &:hover {
    background: #colors[primary];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    padding: 2em;
    align-items: center;
  }

  .btn-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #colors[white];
  }
}

.he-btn {
  width: 100% !important;
  background: #colors[primary];
  outline: none;
  color: white;
  border: none;
  border-radius: 3px;
  height: 45px;
}


.he-button-yellow {
  background: #colors[yellow];
  box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
}