@import "../styles/theme.less";

.he-form {
    .label {
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #colors[black];
    }
    
    .input {
        background: #colors[white];
        border: 1px solid #D4DBE3;
        box-sizing: border-box;
        border-radius: 3px;
        height: 50px;
    }
    
}
